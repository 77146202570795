// require('./bootstrap');
import './bootstrap';
import Swal from 'sweetalert2';

window.addEventListener('swal2-event', (e) => {
    const swal2details = e.detail;
    Swal.fire({...swal2details});
});

window.Swal = Swal;
